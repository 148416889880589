import { Row, Col, Checkbox } from "antd";
import { makeRequest, removeById, notify } from '@utils/helpers';
import { getChangePermissionReq } from './requests';

const GetPermissions = (props) => {
    const onChangePermission = (event, id, key) => {
        let check = event.target.checked;
        if (check) {
            let payload = {
                action: 'create',
                role_id: props.roleId,
                module_id: id,
                key: key
            }
            makeRequest(Function, getChangePermissionReq, payload, onSuccess, onError);
        } else {
            let payload = {
                action: 'delete',
                role_id: props.roleId,
                module_id: id,
                key: key
            }
            makeRequest(Function, getChangePermissionReq, payload, onSuccess, onError);
        }
    }

    const onSuccess = (res, response) => {
        notify("Success", response.msg);
        window.location.reload();
    }

    const onError = (err) => {

    }

    const checkPermission = (permission, module, roleid) => {
        let data = permission.filter(x => x.role_id == roleid && x.module_id == module);
        return data.length;
    }


    const GetPermissions = (module) => {
        return (
            <>
                { module.map((eachModule, i) =>
                    <div className="da-my-32" key={ i }>
                        <div className="da-mb-16 da-p-8" style={ { border: 'solid 1px lightgrey', borderRadius: '5px' } }>
                            <Row gutter={ 16 }>
                                <Col span={ 12 }>
                                    <strong>{ eachModule.name }</strong>
                                </Col>

                                <Col span={ 12 } >
                                    <Checkbox
                                        checked={
                                            checkPermission(props.data.permissions, eachModule.id, props.roleId) > 0 ? true : false
                                        }
                                        onChange={ (event) => onChangePermission(event, eachModule.id, eachModule.key) }
                                        id={ eachModule.key }
                                    />

                                    <label className="da-ml-16" for={ eachModule.key }> Allow</label>
                                </Col>
                            </Row>
                        </div>
                    </div>
                ) }
            </>
        );
    }

    return (
        <> { GetPermissions(props.modules) }</>
    );

}
export default GetPermissions;
