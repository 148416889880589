import React from "react";

import { Layout, Button, Row, Col } from "antd";
import { RiMenuFill } from "react-icons/ri";
import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
import "./header-styles.css"

const { Header } = Layout;

export default function MenuHeader(props) {
  const { setVisible } = props;

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
  };

  return (
    <Header className={ "headerStyle" }>
      <Row className="da-w-100" >
        <Col xs={ 10 } sm={ 11 } lg={ { span: 11, offset: 0 } } xl={ { span: 0, offset: 0 } } xxl={ { span: 0, offset: 0 } } className="da-mobile-sidebar-button da-mr-24">
          <Button
            className="da-mobile-sidebar-button"
            type="text"
            onClick={ showDrawer }
            icon={
              <RiMenuFill
                size={ 24 }
                className="remix-icon da-text-color-black-80"
              />
            }
          />
        </Col>

        <Col xs={ 10 } sm={ 12 } lg={ { span: 12, offset: 0 } } xl={ { span: 24, offset: 0 } } xxl={ { span: 24, offset: 0 } }>
          <Row className="da-float-right">
            <Col className="da-d-flex-center da-mr-sm-12 da-mr-16">
            </Col>
            <Col style={ {
              cursor: 'pointer',
            } }>
              <HeaderUser />
            </Col>
          </Row>
        </Col>
      </Row>
    </Header >
  );
};
