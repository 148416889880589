import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexSetting from "./IndexSetting";

const SettingModule = ({ match }) => (
  <Switch>
    <Route exact path={ `${match.url}` } component={ IndexSetting } />
  </Switch>
);

export default SettingModule;
