import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, Form, Input, Row } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import noimage from '@assets/img/noimage.jpg';
import { getErrorProps } from "@utils/helpers";
import { ButtonComponent } from "@comps/components";
import { notify } from './../../../../utils/helpers';
const formName = "resetPasswordForm";

const ResetPassword = () => {
  const history = useHistory();
  const [webInData, setWebInData] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [loaderState, setLoaderState] = React.useState(false);

  React.useEffect(() => {
    let webData = localStorage.getItem('webData');
    setWebInData(JSON.parse(webData));
    if (!webData) {
      axios.get(process.env.REACT_APP_API_BASE_URL + '/get-initial-data').then((res) => {
        localStorage.setItem('webData', JSON.stringify(res.data.data));
        setWebInData(res.data.data);
      })
    }


  }, []);

  const onFinish = (data) => {
    setLoaderState(true);
    let payload = {
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
      token: window.location.href.split('/').pop()
    }
    axios.post(process.env.REACT_APP_API_BASE_URL + '/reset-password', payload).then((res) => {
      if (res.data.code === 200) {
        notify('success', 'Password reset successfully')
        history.push('/')
        setLoaderState(false);
      } else {
        setLoaderState(false);
        notify('error', res.data.msg)
      }
    })
  }


  const LoginForm = (props) => (
    <>
      <h3 className="da-mb-sm-0">Reset Password</h3>
      <p className="da-mt-sm-0 da-mt-8 da-text-color-black-60">
        Email verification is done. Please choose another password
      </p>
      <Form
        layout="vertical"
        name="basic"
        initialValues={ { remember: true } }
        className="da-mt-sm-16 da-mt-32"
        onFinish={ onFinish }
      >
        <Form.Item name="email" rules={ rules.email } label="Email" className="da-mb-16"
          { ...getErrorProps(errors['email']) }>
          <Input placeholder="Enter Email" />
        </Form.Item>

        <Form.Item name="password" rules={ rules.password } label="Password :"
          { ...getErrorProps(errors['password']) }
        >
          <Input.Password
            placeholder="At least 6 characters"
          />
        </Form.Item>

        <Form.Item name="password_confirmation" dependencies={ ['password'] } rules={ [{ required: true, message: "Please input your password confirmation!" }, ({ getFieldValue }) => ({ validator(rule, value) { if (!value || getFieldValue('password') === value) { return Promise.resolve(); } return Promise.reject('Password confirmation does not match!'); }, })] } label="Confirm Password" className="da-mb-8"
          { ...getErrorProps(errors['password_confirmation']) }>
          <Input.Password />
        </Form.Item>

        <Form.Item className="da-mt-16 da-mb-8">
          <ButtonComponent style={ { background: props.crmStyle.bg_color, borderColor: props.crmStyle.border_color } } block type="primary" htmlType="submit" state={ loaderState }>
            Reset Password
          </ButtonComponent>
        </Form.Item>
      </Form>
      <div className="da-mt-64 da-text-center">
        <Link
          className="da-button da-text-color-success-80"
          to="/"
        >
          Go Back? Login
        </Link>
      </div>
    </>
  );

  if (webInData === null) return (<div>Loading...</div>);

  return (
    <div className="loginpage" style={ {
      backgroundImage: `url('${webInData.login_background ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webInData.login_background : "/img/loginbg.jpg"}')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      minHeight: '1000px',
      overflow: 'hidden',
    } }>

      <Row gutter={ [32, 0] } >

        <Col lg={ 12 } span={ 24 } >
          <Col xxl={ 24 } xl={ 24 } lg={ 24 } md={ 24 } sm={ 24 } className="da-text-center da-px-sm-8 da-pt-24 da-pr-96">
            <img src={ webInData.letter_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webInData.letter_logo : noimage } width={ 150 } alt="img" />
          </Col>
          <Row align="middle" justify="center">
            <Col
              xxl={ 24 }
              xl={ 24 }
              lg={ 24 }
              md={ 24 }
              sm={ 24 }
              className="da-px-sm-8 da-pt-24 da-pl-96"
            >
              <h2 style={ { paddingTop: 100 } } class="login-header-text">{ webInData.login_text }</h2>
            </Col>
          </Row>
        </Col>
        <Col lg={ 12 } span={ 24 } >
          <Row align="middle" justify="center">
            <Col
              xxl={ 11 }
              xl={ 15 }
              lg={ 20 }
              md={ 20 }
              sm={ 24 }
              className="da-px-sm-8 da-pt-24 da-pb-48"
            >
              <div className="loginbox">
                { <LoginForm crmStyle={ webInData.style } /> }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

}

export default ResetPassword;

const rules = {
  password: [
    { required: true, message: 'Please input your password!', },
    { min: 6, message: 'Minimum password length is 6', },
    { max: 30, message: 'Maximum password length is 30', },
  ],
  email: [
    { type: "email", message: "The input is not valid email!" },
    { required: true, message: "Please input your email!" },
  ],
  password_confirmation: [
    { required: true, message: "Please input your password confirmation!" },
    { validator: (rule, value, callback) => { if (value && value !== formName.getFieldValue('password')) { callback('Password confirmation does not match!'); } else { callback(); } } },
  ],
};
